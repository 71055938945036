import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import { ifNotNull } from '../../utils/directives';
import getString from '../statusindicator/strings';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './statusindicator.lit.scss';

const ICON_MAP = {
  success: 'checkmark',
  error: 'danger',
  alert: 'warning',
  information: 'info-circle',
  pending: 'clock',
};

enum StatusIndicatorVariants {
  success,
  error,
  alert,
  information,
  pending,
  loading,
}

type StatusIndicatorVariant = keyof typeof StatusIndicatorVariants;

function getIconStringForType(type: StatusIndicatorVariant, label: string) {
  return getString(`katal-statusindicator-${type}`, { label });
}

/**
 * @component {kat-statusindicator} KatalStatusindicator A status indicator quickly communicates the status of a specific item, action, or other resource. This component is small and is intended to be embedded into tables, lists, or other containers.
 * @example Success {"variant": "success", "label":"Success"}
 * @example Error {"variant": "error", "label":"Error"}
 * @example Alert {"variant": "alert", "label":"Alert"}
 * @example Information {"variant": "information", "label":"Information"}
 * @example Pending {"variant": "pending", "label":"Pending"}
 * @example Loading {"variant": "loading", "label":"Loading"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-statusindicator')
export class KatStatusindicator extends KatLitElement {
  /**
   * The type of indicator to display to the user.
   * @enum {value} success success
   * @enum {value} error error
   * @enum {value} alert alert
   * @enum {value} information information
   * @enum {value} pending pending
   * @enum {value} loading loading
   */
  @property({ type: String })
  variant: StatusIndicatorVariant = 'success';

  /** The text that will be displayed to the user. */
  @property()
  label: string;

  /** The label used for screen readers. If omitted, the `label` will be used. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  static get styles() {
    return [baseStyles, styles];
  }

  render() {
    const icon =
      this.variant === 'loading'
        ? html`<kat-spinner class="spinner" aria-hidden="true"></kat-spinner>`
        : html`
            <kat-icon
              class="icon"
              aria-hidden="true"
              name="${ICON_MAP[this.variant]}"
            ></kat-icon>
          `;

    const label = this.katAriaLabel ? this.katAriaLabel : this.label;
    const fullAriaLabel = getIconStringForType(this.variant, label);

    return html`
      <div class="wrapper" aria-label="${fullAriaLabel}" aria-live="polite">
        <div class="icon__container"><slot name="icon">${icon}</slot></div>
        <span class="label" aria-hidden="true"
          ><slot>${ifNotNull(this.label)}</slot></span
        >
      </div>
    `;
  }
}
