import { KatAlert, KatBox, KatIcon, KatLabel, KatPopover, KatRadiobutton, KatSpinner, KatTable, KatTableBody, KatTableCell, KatTableRow, KatTextarea } from "@amzn/katal-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import { WidgetFunctions } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions";
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";
import { getPredictedSOPs } from "../../service/actionPlan";
import { PredictSOPsFromCaseInfoResponseData, SOP } from "../../model/ActionPlanTypes";
import $style from './sop-details.module.scss';
import { getParagonDomain, logClickStreamData } from 'src/common/utils';
import { logger } from 'src/logger';
import Sparkle from 'src/common/svgLibrary/sparkle.svg'

type Props = {
    paragonCaseId: string | undefined;
    paragonCaseSubject: string | undefined;
    widgetMethods: WidgetFunctions;
    changeSOPId: (data: string) => void;
    changeSopListFeedback: (data: string) => void;
    setIfSOPListed: (data: boolean) => void;
    isSOPPicked: boolean;
    clickStream: AnalyticsFunctions;
    setIsSteppingSupported: (data: boolean) => void;
}

export const SOPDetails: FunctionComponent<Props> = ({
    paragonCaseId,
    paragonCaseSubject,
    widgetMethods,
    changeSOPId,
    changeSopListFeedback,
    setIfSOPListed,
    isSOPPicked,
    clickStream,
    setIsSteppingSupported }) => {
    const [sopList, setSopList] = useState<SOP[]>([]);
    const [isloadingSOP, setIsloadingSOP] = useState(false);
    const [isError, setIsError] = useState(false);
    const [numberOfSOP, setNumberOfSOP] = useState<number>(3);
    const [isSopNotListed, setIsSopNotListed] = useState(false);
    const domain: string = getParagonDomain(window.location.origin);
    const standardError: string = "Sorry, something went wrong. The list of recommended SOPs cannot be generated at this time. Please follow standard procedures to address the case and try again later.";
    const [errorHeader, setErrorHeader] = useState('Error generating list of SOPs');
    const [errorMessage, setErrorMessage] = useState(standardError);

    useEffect(() => {
        setIsloadingSOP(true);
        if (!paragonCaseId) {
            return;
        }
        getPredictedSOPs(paragonCaseId, paragonCaseSubject, numberOfSOP, widgetMethods).then((response) => {
            if (response.success) {
                setIsError(false)
                const predictSOPsFromCaseInfoResponse: PredictSOPsFromCaseInfoResponseData = response.data;
                const sopList = predictSOPsFromCaseInfoResponse.sopList;
                logClickStreamData(clickStream, "click", "action-plan-widget-sop-recommended", {
                    caseId: paragonCaseId,
                    sopList: predictSOPsFromCaseInfoResponse.sopList,
                });
                logger.info(`[action-plan-widget-sop-recommended] For case: ${paragonCaseId} are ${sopList}`);
                const allSopList = sopList.concat({ title: 'SOP not listed', id: 'none', description: '', flags: ["STEPPING_NOT_SUPPORTED"] })
                setSopList(allSopList);
            } else {
                const message = response.message.toLowerCase();
                if (message.includes('failed to predict sops from case info')) {
                    logClickStreamData(clickStream, "click", "action-plan-widget-sop-error", { caseId: paragonCaseId, errorCode: "Failed to predict SOPs" });
                    logger.info(`[action-plan-widget-sop-error] For case: ${paragonCaseId}`);
                    setErrorHeader('Unable to generate SOP recommendations');
                    setErrorMessage('Currently we are unable to generate SOP recommendations for non-email cases. Please follow standard procedures to address the case.');
                }
                else if (message.includes('timeout waiting for task')) {
                    logClickStreamData(clickStream, "click", "action-plan-widget-sop-error", { caseId: paragonCaseId, errorCode: "Timeout error" });
                    logger.info(`[action-plan-widget-sop-error] For case: ${paragonCaseId}`);
                }
                setIsError(true);
            }
        }).catch(() => {
            setIsError(true)
            console.log("SOP fetch failed");
        }).finally(() => {
            setIsloadingSOP(false);
        })
    }, [paragonCaseId])

    return (
        <div>
            <KatBox variant="white" className={$style.mainBox}>
                <h6>Select a recommended SOP</h6>
                {isloadingSOP &&
                    <KatSpinner variant="default" size="small"></KatSpinner>
                }
                {isError && <KatAlert variant='warning'
                    header={errorHeader}
                    description={errorMessage}
                />
                }
                {sopList && (
                    <div>
                        {sopList.map((sopDetails, index) => (
                            <KatRadiobutton
                                className={$style.sopRadioButton}
                                name="katal-radio-group-sop-list"
                                key={index}
                                value={sopDetails.id}
                                constraint-label={sopDetails.description}
                                onChange={(e) => {
                                    if ((e.target as HTMLInputElement).value === "none") {
                                        changeSOPId((e.target as HTMLInputElement).value)
                                        setIsSopNotListed(true);
                                        setIfSOPListed(false);
                                    } else {
                                        changeSOPId((e.target as HTMLInputElement).value);
                                        setIsSopNotListed(false);
                                        setIfSOPListed(true);
                                        setIsSteppingSupported(!sopDetails.flags?.includes("STEPPING_NOT_SUPPORTED"));
                                    }
                                }}>
                                <div>
                                    <div className={$style.sopOption}>
                                        {!sopDetails.flags?.includes("STEPPING_NOT_SUPPORTED") &&
                                            <KatPopover kat-aria-behavior="tooltip"
                                                position="top-start"
                                                trigger-type="hover">
                                                <img src={Sparkle} className={$style.starIcon} slot="trigger" />
                                                <label>Clicking 'Next' will generate a series of tasks and outcomes from this SOP
                                                    to guide you in resolving the issue.</label>
                                            </KatPopover>
                                        }
                                        <div>
                                            {sopDetails.title}
                                        </div>
                                        {sopDetails.id != 'none' &&
                                            <a href={domain + "/hz/codex?id=" + sopDetails.id}
                                                target="_blank"
                                                className={$style.sopLink}
                                                onClick={() => {
                                                    logClickStreamData(clickStream, "click", "action-plan-widget-sop-link-clicked", {
                                                        caseId: paragonCaseId,
                                                        sopId: sopDetails.id
                                                    });
                                                    logger.info(`[action-plan-widget-sop-link-clicked] For case: ${paragonCaseId} is ${sopDetails.id}`);
                                                }}
                                            >
                                                <KatIcon name="launch" size="small" />
                                            </a>
                                        }
                                    </div>
                                    <KatLabel
                                        state="default"
                                        text={sopDetails.description}
                                        variant="constraint"
                                        className={$style.sopDescription}
                                    />
                                </div>
                            </KatRadiobutton>
                        ))}
                        {isSopNotListed && <KatTextarea
                            className={$style.textArea}
                            placeholder="Enter a more relevant SOP link"
                            onKeyup={(e: any) => {
                                const url = new URL(e.target.value);
                                const id = url.searchParams.get("id")
                                if (id) {
                                    changeSOPId(id);
                                }
                                changeSopListFeedback(e.target.value);
                            }}
                        ></KatTextarea>
                        }
                    </div>
                )}
            </KatBox>
        </div>
    );
};